import React, { useState, useEffect } from "react";

//import { classlist, api, slugify } from '../data';

import {url} from "../../../common/shared";
import { useNavigate } from "react-router-dom";
//import { apiBase, adminBase, nonAuth, loginpath } from "./config";
//import { nonAuth } from "../routr";

//import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";
//const MySwal = withReactContent(Swal);

const TrackCtx = React.createContext();
/*https://app.cryptotradeoptions.net/dashboard*/

export const useTrackCtx = () => React.useContext(TrackCtx);

export const TrackProvider = ({ children }) => {
    //house crud methods

    const [item,sItem]=useState();
    const [orderId,sOrderId]=useState();
    const [notice,sNotice]=useState();
    //notice
 
    const getD=()=>{
        //let url="";


        let r=new FormData();//orderId
        r.append("check_delivery","check_delivery"); 
        r.append("token",orderId); 

        fetch(`${url}/api/user.php`, {
          method: "POST",
          body: r
        }).then((res) => res.json()).then((c)=>{
        	sItem(c);
        	//sNotice(c);

        	if (!c?.active && !c?.other) {
        		sNotice({type:'error',heading:'Invalid Tracking Code',message:'contact support for any issues or compliant '});
        	}else{}
            /*setD(true);
            if (c?.status) {
                setDa({...c?.user});
                sOldData(c?.user);
                setE({});
            }*/
        }).catch((e)=>{
            console.log(e);
        });
    }
 

    return (
        <TrackCtx.Provider
            value={{getD,item,sItem,sOrderId,orderId,
            	notice
            }}
        >
            {children}
        </TrackCtx.Provider>
    );
};
